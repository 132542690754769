/* eslint-disable no-param-reassign */
import { Room } from 'livekit-client';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IDynamicLayout } from '../../components/DynamicLayout/types';
import { RootState } from '../store';
import { IRoom, streamGoApi } from '../streamGoApi';

export interface IQueryString {
  chat: boolean;
  displayname: string;
  hidelogin: boolean;
  hq: boolean;
  lowcpumode: boolean;
  roomid: string;
  screenshare: boolean;
  token: string;
  video: boolean;
}

export interface IRoomState {
  audioOutputDeviceId?: string;
  customeVirtualBackgrounds: string[];
  displayName?: string;
  draftLowerThirdText?: string;
  dynamicLayout?: IDynamicLayout;
  hasBackgroundBlur: boolean;
  hasVirtualBackground: boolean;
  hideLoginButton?: boolean;
  isPresentGo: boolean;
  liveNowUsers: string[];
  lowerThirdsPercentage: number;
  onScreenParticioantIds: string;
  queryString: IQueryString;
  room?: Room;
  roomName?: string;
  streamGoRoom?: IRoom;
  virtualBackground: string;
}

export const defaultQueryString = {
  chat: false,
  displayname: '',
  hidelogin: false,
  hq: false,
  lowcpumode: false,
  roomid: '',
  screenshare: true,
  token: '',
  video: true,
};

const initialState: IRoomState = {
  audioOutputDeviceId: 'default',
  dynamicLayout: {},
  hasBackgroundBlur: false,
  hasVirtualBackground: false,
  customeVirtualBackgrounds: [],
  virtualBackground: '',
  lowerThirdsPercentage: 0,
  isPresentGo: false,
  liveNowUsers: [],
  onScreenParticioantIds: '',
  queryString: defaultQueryString,
};

const slice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setRoomName: (state, payload: PayloadAction<string>) => {
      state.roomName = payload.payload;
    },
    setDisplayName: (state, payload: PayloadAction<string>) => {
      state.displayName = payload.payload;
    },
    setIsPresentGo: (state, payload: PayloadAction<boolean>) => {
      state.isPresentGo = payload.payload;
    },
    setCustomVirtualBackgrounds: (state, payload: PayloadAction<string>) => {
      state.customeVirtualBackgrounds = [
        payload.payload,
        ...state.customeVirtualBackgrounds,
      ];
    },
    setHasBackgroundBlur: (state, payload: PayloadAction<boolean>) => {
      state.hasBackgroundBlur = payload.payload;
    },
    setLowerThirdsPercentage: (state, payload: PayloadAction<number>) => {
      state.lowerThirdsPercentage = payload.payload;
    },
    setDynamicLayoutQuestion: (state, payload: PayloadAction<string>) => {
      state.draftLowerThirdText = payload.payload.substring(0, 1000);
    },
    setHasVirtualBackground: (state, payload: PayloadAction<boolean>) => {
      state.hasVirtualBackground = payload.payload;
    },
    setVirtualBackground: (state, payload: PayloadAction<string>) => {
      state.virtualBackground = payload.payload;
    },
    setAudioOutputDeviceId: (state, payload: PayloadAction<string>) => {
      state.audioOutputDeviceId = payload.payload;
    },
    setRoom: (state, payload: PayloadAction<Room>) => {
      // @ts-expect-error some error
      state.room = payload.payload;
    },
    setOnScreenParticipantIds: (state, payload: PayloadAction<string>) => {
      state.onScreenParticioantIds = payload.payload;
    },
    setQueryString: (state, payload: PayloadAction<IQueryString>) => {
      state.queryString = payload.payload;
      const { displayname, hidelogin, roomid } = payload.payload;
      state.roomName = roomid;
      state.displayName = displayname;
      state.hideLoginButton = hidelogin;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      action =>
        [
          streamGoApi.endpoints.getRoomDetails.matchFulfilled,
          streamGoApi.endpoints.updatePinned.matchFulfilled,
          streamGoApi.endpoints.updateDynamicLayout.matchFulfilled,
          streamGoApi.endpoints.updateStreamingView.matchFulfilled,
        ].some(matcher => matcher(action)),
      (state, action: PayloadAction<IRoom>) => {
        const streamGoRoom = action.payload;
        state.streamGoRoom = streamGoRoom;
        state.roomName = streamGoRoom.RoomName;

        const dynamicLayoutStr = streamGoRoom.DynamicLayout;
        const liveNowUsers = streamGoRoom.LiveNowUsers;

        if (dynamicLayoutStr !== JSON.stringify(state.dynamicLayout)) {
          state.dynamicLayout = JSON.parse(dynamicLayoutStr) as IDynamicLayout;
        }

        state.liveNowUsers = liveNowUsers ? JSON.parse(liveNowUsers) : [];
      },
    );
  },
});

export const {
  setAudioOutputDeviceId,
  setCustomVirtualBackgrounds,
  setDisplayName,
  setDynamicLayoutQuestion,
  setHasBackgroundBlur,
  setHasVirtualBackground,
  setIsPresentGo,
  setLowerThirdsPercentage,
  setOnScreenParticipantIds,
  setQueryString,
  setRoom,
  setRoomName,
  setVirtualBackground,
} = slice.actions;
export default slice.reducer;

export const selectDisplayName = (state: RootState) => state.room.displayName;
export const selectDynamicLayout = (state: RootState) =>
  state.room.dynamicLayout;
export const selectIsPresentGo = (state: RootState) => state.room.isPresentGo;
export const selectRoom = (state: RootState) => state.room.room;
export const selectRoomName = (state: RootState) => state.room.roomName;
export const selectLiveViews = (state: RootState) =>
  state.room.onScreenParticioantIds;
export const selectLiveNowUsers = (state: RootState) => state.room.liveNowUsers;
export const selectAudioOutputDeviceId = (state: RootState) =>
  state.room.audioOutputDeviceId;
export const selectStreamGoRoom = (state: RootState) => state.room.streamGoRoom;
export const selectConferenceParticipants = (state: RootState) =>
  state.room.streamGoRoom?.Participants ?? [];
export const selectQueryString = (state: RootState) => state.room.queryString;
export const selectHideLoginButton = (state: RootState) =>
  state.room.hideLoginButton;
export const selectHasBackgroundBlur = (state: RootState) =>
  state.room.hasBackgroundBlur;
export const selectHasVirtualBackground = (state: RootState) =>
  state.room.hasVirtualBackground;
export const selectVirtualBackground = (state: RootState) =>
  state.room.virtualBackground;
export const selectCustomVirtualBackgrounds = (state: RootState) =>
  state.room.customeVirtualBackgrounds;
export const selectDraftLowerThirdText = (state: RootState) =>
  state.room.draftLowerThirdText;
export const selectLowerThirdsPercentage = (state: RootState) =>
  state.room.lowerThirdsPercentage;
