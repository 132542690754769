export enum StreamingView {
  Unset = -1,
  WebCamsActiveSpeaker = 1,
  WebCamsGrid = 2,
  FullScreenSlides = 3,
  FullScreenSlidesNoSound = 35,
  WebCamsActiveSpeakerPinned = 36,
  SlidesPinned = 37,
  SlidesWebCamSplit8020Wrsl = 28,
  FullScreenScreenShare = 14,
  ScreenSharePinned = 38,
  ScreenshareWebCamSplit8020Wrsl = 32,
  SlidesWebCamPipTopRight = 11,
  SlidesWebCamPipBottomRight = 13,
  SlidesWebcamSplit11Wrsl = 41,
  SlidesWebcamSplit169Wrsl = 44,
  ScreenshareWebCamPipTopRight = 22,
  ScreenshareWebCamPipBottomRight = 24,
  ScreenshareWebcamSplit11Wrsl = 48,
  ScreenshareWebcamSplit169Wrsl = 50,
  PreRoll = 25,
  PostRoll = 26,
}

export enum StreamingViewType {
  StreamingView = 'StreamingView',
  StreamingViewDefault = 'StreamingViewDefault',
}

export enum SideBySideStreamingViews {
  ScreenshareWebcamSplit11Wrsl = 48,
  ScreenshareWebcamSplit169Wrsl = 50,
  ScreenshareWebCamSplit8020Wrsl = 32,
  SlidesWebcamSplit11Wrsl = 41,
  SlidesWebcamSplit169Wrsl = 44,
  SlidesWebCamSplit8020Wrsl = 28,
}

export enum PrimaryBackup {
  Primary = 1,
  Backup = 2,
}

export enum AspectRatio {
  RatioDefault = 1,
  Ratio11 = 2,
  Ratio43 = 3,
  Ratio169 = 4,
}

export enum ParticipantOutline {
  None = 0,
  Outline = 1,
}

export enum SettingType {
  ApiUrl = 'ApiUrl',
  IsDebugMode = 'IsDebugMode',
  PollInterval = 'PollInterval',
  WebcamGoPostRollVideoUrl = 'WebcamGoPostRollVideoUrl',
  WebcamGoPreRollVideoUrl = 'WebcamGoPreRollVideoUrl',
}

export enum UserRole {
  User = 0,
  Moderator = 1,
  Admin = 2,
}
